import React from 'react'
import styled from 'styled-components'
import image from './assets/aboutme.webp'

const AboutMe = () => {
  return (
    <AboutMeContainer>
        <img src={image} alt='Me with a cinema clapperboard' />
        <TextDiv>
          <h2>Film director<br/> based in Paris</h2>
          <p>
          During his years in Barcelona, he premiered his first short film, <i>Vapor</i>, starring Biel Castaño, in a short films screening organized by <i>ArtES Medea</i> in 2022. He also worked with them on a photographic exhibition. The film has been featured in <i>Lift-Off Filmmaker Sessions</i> at <i>PinewoodStudios</i>.<br></br><br></br>On the other hand, he has worked as an assistant director, director of photography and producer in projects by other filmmakers such as Marta del Hoyo, Oliver Salvador and Sergi Carreras.<br></br><br></br>He currently resides in Paris, where he is developing a new film with the advice of Mexican filmmaker Carolina Rivas.
          </p>
        </TextDiv>
    </AboutMeContainer>
  )
}

export default AboutMe

const AboutMeContainer = styled.section`
    width: 90%;
    height: 100vh;
    border-left: 5px solid black;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    @media only screen and (min-width: 1200px) {
      flex-direction: row;
      align-items: flex-start;
    }
    img {
      object-position: top;
      width: 100%;
      height: 40%;
      object-fit: cover;
      filter: grayscale(100%);
      @media only screen and (min-width: 426px) {
        width: 250px;
        height: auto;
        margin: 10px 0;
      }
      @media only screen and (min-width: 1200px) {
      width: 600px;
      margin: 87px 0 0 75px;
      }
    }
`
const TextDiv = styled.div`
  overflow: scroll;
  padding-bottom: 80px;
  h2, p {
    padding: 10px;
    font-size: 100%;
  }
  h2 {
    font
  }
  @media only screen and (min-width: 426px) {
    width: 80%;
  }
  @media only screen and (min-width: 800px) {
    width: 40%;
  }
  @media only screen and (min-width: 1200px) {
    overflow: unset;
    padding-bottom: unset;
    margin-top: 110px;
    width: 770px;
    right: 0;
    position: absolute;
    h2 {
      font-size: 60px;
      text-align: right;
      padding-right: 120px;
    }
    p {
      text-align: left;
      position: absolute;
      margin-left: 170px;
      width: 450px;
      font-size: 17px;
      line-height: 1.7rem;
    }
  }
`
